<template>
  <div class="background" v-loading="loading">
    <el-row class="row-bg" justify="space-evenly">
      <el-col :span="8" class="mask">
        <div class="text">
          疫苗预约后台管理系统
        </div>
        <div class="form">
          <el-form label-position="top" label-width="auto" :model="formData" size="large">
            <el-form-item label="账号">
              <el-input v-model="formData.createid" placeholder="请输入账号/工单" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="formData.password" placeholder="请输入密码" type="password" />
            </el-form-item>
            <el-button type="primary" style="width: 100%;" @click="toLaunch">Login</el-button>
            <div style="float: right;margin: 15px 0 0 0;text-decoration: underline;" @click="toRegist">
              没有账号？去注册
            </div>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import router from '@/router'
import { ref } from 'vue'
import { verification } from '../utils/API'
import { ElMessage } from 'element-plus'
const formData = ref({
  createid: '',
  password: ''
})
const loading = ref(false)
const toLaunch = () => {
  loading.value = true

  verification(formData.value).then((res) => {
    console.log(res)
    if (res.data !== '') {
      ElMessage({
        message: '发送成功，等待响应',
        type: 'success',
      })
      sessionStorage.setItem('hospitalInfo', JSON.stringify(res.data));
      setTimeout(function () { router.replace({ path: "/admin" }); }, 1500);
    }else{
      ElMessage({
        message: '账号密码错误',
        type: 'error',
      })
      loading.value = false
    }

  })
  // loading.value = false

}
const toRegist = () => {

  router.replace({ path: "/regist" })
}
</script>

<style lang="scss" scoped>
.background {
  width: 100%;
  background: url("../assets/LoginBG.png") no-repeat;
  height: 100vh;
  overflow: hidden;
  background-size: cover;

  .mask {
    position: fixed;
    width: 100%;
    top: 35%;
    left: 50%;
    box-sizing: border-box;
    padding: 20px 40px;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    background-color: rgba(253, 253, 253, 0.5);
    backdrop-filter: blur(10px);
    text-align: center;

    .text {
      color: rgb(46, 46, 46);
      font-size: 20px;
    }
  }
}
</style>