<template>
  <div class="background">
    <el-row class="row-bg" justify="space-evenly">
      <el-col :span="8" class="mask">
        <div class="text">
          注册账号
        </div>
        <div class="form">
          <el-form label-position="top" label-width="auto" :model="formData" size="large">
            <el-form-item label="手机号">
              <el-input v-model="formData.phone" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item label="医院名称">
              <el-input v-model="formData.hospital" placeholder="请输入医院名称" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="formData.password" placeholder="请输入密码" type="password" />
            </el-form-item>
            <el-form-item>
              <el-col :span="14"><el-input v-model="formData.verifyCode" placeholder="请输入验证码"
                  type="verifyCode" /></el-col>
              <el-col :span="8" :offset="2"><el-button type="primary" plain style="width: 100%;"
                  @click="sendVerifyCode">发送验证码</el-button></el-col>
            </el-form-item>
            <el-button type="primary" style="width: 100%;" @click="sendRegistInfo">注册</el-button><br /><br />
            <el-button type="primary" style="width: 100%;" @click="returnLaunch" plain>返回登录</el-button>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import router from '@/router'
import { ref } from 'vue'
import { getSMS, postHospitalInfo } from '../utils/API'
import { ElMessage } from 'element-plus'
const formData = ref({
  phone: "",
  hospital: "",
  password: "",
  verifyCode: ""
})
const sendVerifyCode = () => {
  getSMS(formData.value.phone).then((res) => {
    console.log(res)
  })
  console.log("Launch")
}
const sendRegistInfo = () => {
  postHospitalInfo(formData.value).then((res) => {
    if (res.status == 200) {
      ElMessage({
        message: '成功，等待返回跳转',
        type: 'success',
      })
      setTimeout(function () { router.replace({ path: "/" }); }, 1500);

    }
  })
}
const returnLaunch = () => {
  router.replace({ path: "/" })
}
</script>

<style lang="scss" scoped>
.background {
  width: 100%;
  background: url("../assets/202131721342.png") no-repeat;
  height: 100vh;
  overflow: hidden;
  background-size: cover;

  .mask {
    position: fixed;
    width: 100%;
    top: 35%;
    left: 50%;
    box-sizing: border-box;
    padding: 20px 40px;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    background-color: rgba(253, 253, 253, 0.5);
    backdrop-filter: blur(10px);
    text-align: center;

    .text {
      color: rgb(46, 46, 46);
      font-size: 20px;
    }
  }
}
</style>