<template>
    <div style="width: 100%;height: 100%;box-sizing: border-box;">
        <el-empty description="还没有用户" v-if="!isUser" />
        <div class="Chat" v-if="isUser">
            <el-row style="height: 80%;">
                <el-col :span="6" class="userlist" style=" height: 100%;overflow-y: auto;overflow-x: hidden;">
                    <div v-for="(messages, sender) in messageMap" :key="sender" @click="change(sender)" class="box-left"
                        :class="{ 'selected': selectedItem === sender }">
                        <div class="user-left">
                            {{ sender }}
                        </div>

                        <div class="user-right">
                            <div class="ball" v-if="userMessageNumber[sender] && userMessageNumber[sender] > 0">
                                {{ userMessageNumber[sender] }}
                            </div>
                            <!-- {{ username }} - 新消息数：{{ count }} -->
                        </div>
                    </div>
                </el-col>
                <el-col :span="18" style="height: 100%;">
                    <div class="message" ref="ChatContent" style="height: 100%;overflow-y: auto;overflow-x: hidden;">
                        <div v-for="item in Message" :key="item">
                            <div class="left" v-if="item.type === 'user'">
                                <div class="time">{{ item.time }}</div>
                                <div class="box">
                                    <div class="text">用户</div>
                                    <div class="message">{{ item.message }}</div>
                                </div>
                            </div>
                            <div class="right" v-if="item.type === 'hospital'">
                                <div>{{ item.time }}</div>
                                <div class="box">
                                    <div class="message">{{ item.message }}</div>
                                    <div class="text">医院</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>


            <div class="send" style="height: 20%;">
                <el-row style="height: 100%;">
                    <el-col :span="6" style="height: 100%;overflow-y: auto;overflow-x: hidden;">
                        <div ref="userLogs">
                            <div v-for="item in userLog" :key="item" style="">
                                {{ item }}
                            </div>
                        </div>

                    </el-col>
                    <el-col :span="18">
                        <el-row>
                            <el-col :span="22">
                                <el-input v-model="message.message" style="width: 100%;height: 100%;border: none;"
                                    :autosize="{ minRows: 7, maxRows: 8 }" type="textarea" placeholder="Please input"
                                    @keydown="Listening($event)" />
                            </el-col>
                            <el-col :span="2">
                                <button type="primary" plain @click="sendMessage"
                                    style="width: 100%;height: 100%;">发<br />送</button>
                            </el-col>
                        </el-row>


                    </el-col>
                </el-row>



            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, onUnmounted, watch, nextTick, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
const route = useRoute()

const userInfo = ref({})
const hospitalInfo = ref()
const ws = ref()
// 认证信息
const createInfo = ref({
    "username": "",
    "userType": "hospital",
    "hospitalname": ""
})
// 是否有用户
const isUser = ref(false)
onBeforeMount(() => {
    console.log(Object.prototype.isPrototypeOf(userMessageNumber.value) && Object.keys(userMessageNumber.value).length === 0)

    // 获取缓存
    if (window.sessionStorage.getItem("hospitalInfo")) {
        hospitalInfo.value = JSON.parse(window.sessionStorage.getItem("hospitalInfo"));
        createInfo.value.username = hospitalInfo.value.hospitalName
        createInfo.value.hospitalname = hospitalInfo.value.hospitalName
    }
    // 建立连接
    ws.value = new WebSocket('wss://api.ywystation.cn/websocket/' + JSON.stringify(createInfo.value).trim());
    // 监听连接
    ws.value.onopen = (e) => {
        console.log("已经连通了websocket", e);
    };
    // 监听消息
    ws.value.onmessage = (event) => {
        // console.log('收到 WebSocket 消息：', event.data);
        const temp = JSON.parse(event.data)
        console.log('输出temp', temp);
        // console.log('输出userList', userList.value);
        if (temp.state == '上线') {
            if (userLog.value.length > 50) {
                userLog.value.splice(0, 0)
            }
            userLog.value.push("用户" + temp.stateUser + "上线！")
        }
        if (temp.state == '新消息') {
            if (userLog.value.length > 50) {
                userLog.value.splice(0, 0)
            }
            userLog.value.push("用户" + temp.fromusername + "新消息！")
            if (route.path != "/Chat") {
                sendToFather(temp.fromusername)
            }

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1; // 月份是从 0 开始的，所以需要加 1
            const day = currentDate.getDate();
            const hours = currentDate.getHours();
            const minutes = currentDate.getMinutes();
            const time = year + "-" + month + "-" + day + " " + hours + ":" + minutes;
            const temp1 = { username: temp.fromusername, type: 'user', message: temp.textMessage, time: time }
            if (!messageMap.value[temp.fromusername]) {
                // 如果没有，则添加该发件人，并将消息绑定到发件人上
                messageMap.value[temp.fromusername] = [];
                userMessageNumber.value[temp.fromusername] = 0;
            }
            if (temp.fromusername != toUser.value) {
                userMessageNumber.value[temp.fromusername]++;
            }
            // 追加新消息到发件人上
            messageMap.value[temp.fromusername].push(temp1);
            console.log(Object.prototype.isPrototypeOf(userMessageNumber.value) && Object.keys(userMessageNumber.value).length === 0)
            if (!(Object.prototype.isPrototypeOf(userMessageNumber.value) && Object.keys(userMessageNumber.value).length === 0)) {
                isUser.value = true
                console.log("有没有用户", isUser.value)
            }
        }
        if (temp.state == '下线') {
            if (messageMap.value[temp.stateUser]) {
                delete messageMap.value[temp.stateUser];
                Message.value = ""
                selectedItem.value = null
                // userMessageNumber.value[temp.stateUser]
            }
            if (userLog.value.length > 50) {
                userLog.value.splice(0, 0)
            }
            userLog.value.push("用户" + temp.stateUser + "已离开！")
        }

    };
    // 监听关闭
    ws.value.onclose = (event) => {
        console.log('已关闭', event.data);
    };
});
// 用户日志
const userLog = ref([])
// 向父组件传递消息
const emit = defineEmits(['ToFather'])
const sendToFather = (user) => {
    emit("ToFather", user + '有新消息了！')
}
// 获取虚拟DOM节点
const ChatContent = ref(null)
const userLogs = ref(null)
// 总消息库
const messageMap = ref({})
// 单个用户消息
const Message = ref({})
// 发往哪里以及现在在哪
const toUser = ref()
// 每个用户的消息数
const userMessageNumber = ref({})
// 消息模版
const message = ref({
    "username": "",
    "message": "Hello",
    "to": "",
})
const change = (info) => {
    // 选择器赋值
    selectedItem.value = info
    console.log()
    // 计数归零
    userMessageNumber.value[info] = 0
    // 当前聊天用户赋值
    toUser.value = info
    // 取出当前用户的聊天信息
    Message.value = messageMap.value[info]
}
const Listening = (e) => {
    if (e.keyCode == 13) {
        e.preventDefault();
        sendMessage();
    }
}
const sendMessage = () => {
    if (message.value.message.trim() == "") {
        ElMessage({
            message: '输入框不能为空',
            type: 'error',
        })
        return
    }
    console.log(toUser.value)
    if (!toUser.value) {
        ElMessage({
            message: '你还没有选择回复对象',
            type: 'error',
        })
        return
    }
    message.value.to = toUser.value
    message.value.username = hospitalInfo.value.hospitalName
    // 将自己发送的消息交给对应消息盒子
    const temp1 = { username: hospitalInfo.value.hospitalName, type: 'hospital', message: message.value.message }
    messageMap.value[toUser.value].push(temp1);
    console.log(message.value)

    ws.value.send(JSON.stringify(message.value));
    message.value.message = ''
}
// 当前选中的是谁
const selectedItem = ref(null);

const closeSocket = () => {
    ws.value.close();
    ws.value.close = (event) => {
        console.log('关闭WebSocket:', event.data);
    }
    ws.value.onclose = (event) => {
        console.log('已关闭', event.data);
    };
}
watch(() => Message, () => {
    nextTick(() => {
        if (Message.value) {
            ChatContent.value.lastElementChild.scrollIntoView()
        }
        // ChatContent.value.lastElementChild.scrollIntoView()
    })
}, {
    deep: true//深度监听
},
)
watch(() => userLog, () => {
    nextTick(() => {
        if (userLog.value && userLogs.value) {
            userLogs.value.lastElementChild.scrollIntoView()
        }
        // ChatContent.value.lastElementChild.scrollIntoView()
    })
}, {
    deep: true//深度监听
},
)
onUnmounted(() => {
    closeSocket();
})
</script>

<style lang="scss" scoped>
.Chat {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #a1a1a1;

    .userlist {
        border-right: 1px solid #a1a1a1;

        .box-left {
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            align-items: center;
            height: 40px;
            background: rgba(234, 232, 231, 0.8);

            // line-height: 60px;
            // height: 100%;
            .user-left {}

            .user-right {
                .ball {
                    text-align: center;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background: rgba(250, 81, 81, 1.0);
                    color: aliceblue;
                }

            }
        }

        .box-left:hover {
            background: rgba(223, 220, 219, 1.0);
        }
    }

    .message {
        box-sizing: border-box;

    }

    .left {
        width: 100%;

        // display: flex;
        .time {
            text-align: center;
            font-size: 10px;
        }

        .box {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .text {
                width: 50px;
                height: 50px;
                border: black 1px solid;
                border-radius: 50%;
                background: white;
                margin: 10px 10px;
                text-align: center;
                line-height: 50px;
            }

            .message {
                height: 40px;
                margin: 15px 5px;
                font-size: 20px;
                background: rgba(53, 255, 0, 0.774);
                line-height: 40px;
                word-wrap: break-word;
                border-radius: 5px;
                padding: 0 8px;
                box-sizing: border-box;
                box-shadow:
                    inset 0px 0px 6px 2px rgba(95, 230, 104, 0.2),
                    0 0 0 2px rgb(245, 91, 90, 0.1),
                    0.3em 0.3em 1em rgba(245, 91, 91, 0.5);
            }
        }

    }




    .right {
        width: 100%;


        .box {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .text {
                width: 50px;
                height: 50px;
                min-width: 50px;
                min-height: 50px;
                background: rgba(0, 150, 255, 0.2);
                color: aliceblue;
                border: rgba(138, 138, 138, 0.5) 1px solid;
                border-radius: 50%;
                margin: 10px 10px;
                text-align: center;
                line-height: 50px;
            }

            .message {
                width: auto;
                min-height: 40px;
                max-width: 70%;
                margin: 15px 5px;
                font-size: 20px;
                background: rgb(255, 255, 255);
                // text-align: center;
                word-wrap: break-word;
                line-height: 40px;
                padding: 0 8px;
                box-sizing: border-box;
                border-radius: 5px;
                box-shadow:
                    inset 0px 0px 6px 1px rgba(0, 0, 0, 0.2),
                    0 0 0 2px rgb(245, 91, 90, 0.1),
                    -8px 6px 1em rgba(245, 91, 91, 0.5);
            }
        }

    }

    .send {
        border-top: #a1a1a1 1px solid;
    }
}

.message {
    // height: 60px;
}

.selected {
    background-color: yellow !important;
}
</style>