<template>
    <div>


    <div>
        <el-table v-if="PageOrders" :data="PageOrders" border style="width: 100%">
            <el-table-column prop="id" label="单号" />
            <el-table-column prop="serviceid" label="服务ID" />
            <el-table-column prop="userid" label="用户ID" />
            <el-table-column prop="username" label="用户姓名" />
            <el-table-column prop="ordertime" label="下单时间" />
            <el-table-column prop="servicepeopleid" label="工作人员ID" />
            <el-table-column prop="servicepeoplename" label="工作人员姓名" />
            <el-table-column prop="vaccineid" label="疫苗ID" />
            <el-table-column prop="vaccinename" label="疫苗名称" />
            <el-table-column prop="hospitalid" label="医院ID" />
            <el-table-column prop="hospitalname" label="医院名称" />
            <el-table-column prop="serviceaddress" label="服务地址" />
            <el-table-column prop="servicetime" label="服务时间" />
            <el-table-column prop="orderstate" label="订单状态" />
            <el-table-column label="操作" width="140">
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination v-if="total" background @current-change="handleCurrentChange" :page-size=7
                layout="total, prev, pager, next" :total="total" />
        </div>
    </div>
    <div>
        <el-dialog v-model="DialogVisible" title="查看信息" width="700" align-center draggable>
            <!-- <template #content> -->
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="单号">
                        <el-input v-model="formInline.id" disabled/>
                    </el-form-item>

                    <el-form-item label="服务ID">
                        <el-input v-model="formInline.serviceid" disabled/>
                    </el-form-item>

                    <el-form-item label="用户ID">
                        <el-input v-model="formInline.userid"  disabled/>
                    </el-form-item>
                    <el-form-item label="用户名称">
                        <el-input v-model="formInline.username"  disabled/>
                    </el-form-item>
                    <el-form-item label="下单时间 ">
                        <el-input v-model="formInline.ordertime"  disabled/>
                    </el-form-item>
                    <el-form-item label="医师ID ">
                        <el-input v-model="formInline.servicepeopleid"  disabled/>
                    </el-form-item>
                    <el-form-item label="医师名称 ">
                        <el-input v-model="formInline.servicepeoplename"  disabled/>
                    </el-form-item>
                    <el-form-item label="疫苗ID ">
                        <el-input v-model="formInline.vaccineid"  disabled/>
                    </el-form-item>
                    <el-form-item label="疫苗名称 ">
                        <el-input v-model="formInline.vaccinename"  disabled />
                    </el-form-item>
                    <el-form-item label="医院ID ">
                        <el-input v-model="formInline.hospitalid"  disabled/>
                    </el-form-item>
                    <el-form-item label="医院名称 ">
                        <el-input v-model="formInline.hospitalname"  disabled/>
                    </el-form-item>
                    <el-form-item label="服务地址 ">
                        <el-input v-model="formInline.serviceaddress"  disabled />
                    </el-form-item>
                    <el-form-item label="服务时间 ">
                        <el-input v-model="formInline.servicetime"  disabled />
                    </el-form-item>
                    <el-form-item label="订单状态 ">
                        <el-input v-model="formInline.orderstate"  disabled />
                    </el-form-item>
                </el-form>
            <!-- </template> -->
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="DialogVisible=false">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>

    </div>

</div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { getAllOrders,delOrder } from '../../utils/API'

const hospitalInfo = ref()
const Orders = ref()
const total = ref()
const formInline =ref()
const DialogVisible = ref(false)
onBeforeMount(() => {
    if (window.sessionStorage.getItem("hospitalInfo")) {
        hospitalInfo.value = JSON.parse(window.sessionStorage.getItem("hospitalInfo"));
        getOrders();
    }



})
const getOrders = () => {
    getAllOrders(hospitalInfo.value.id).then((res) => {
        Orders.value = res.data
        total.value = Orders.value.length;
        getPageInfo();
    })
}
// 编辑
const handleEdit = (index, row) => {
    DialogVisible.value = true
    formInline.value=row
    // console.log(index, row)
}
// 删除
const handleDelete = (index, row) => {
    delOrder(row.id).then((res)=>{
        console.log(res.data)
        getOrders()
    })
    console.log("删除",index, row)
}
// 一页的数据
const PageOrders = ref()
// 当前在第几页
const currentpage = ref(1)
const getPageInfo = () => {
    //清空pageTicket中的数据
    PageOrders.value = [];
    // 获取当前页的数据
    for (let i = (currentpage.value - 1) * 7; i < total.value; i++) {
        //把遍历的数据添加到pageTicket里面
        PageOrders.value.push(Orders.value[i]);
        //判断是否达到一页的要求
        if (PageOrders.value.length === 7) break;
    }
}
// 分页按钮方法
//调整当前的页码
const handleCurrentChange = (pageNumber) => {
    console.log("当前页", pageNumber)
    //修改当前的页码
    currentpage.value = pageNumber;
    //数据重新分页
    getPageInfo()
}
</script>

<style lang="scss" scoped>
.pagination {
    position: absolute;
    bottom: 10px;
    right: 20px;
}
</style>