<template>
  <div class="common-layout">
    <el-container>
      <el-header class="Header">

        <div class="logo">
          <el-image style="width: 60px;height: 60px;" :src="hospitalInfo.hospitalImg" fit="cover" />
          <div style="margin-left: 10px;font-weight: bold;font-size: larger;">
            {{ hospitalInfo.hospitalName }}
          </div>
        </div>
        <div class="top-center">疫苗预约小程序后台管理系统</div>
        <div class="top-right" @click="Quit">关闭 <img src="@/assets/关机.png" /></div>


      </el-header>
      <el-container>
        <el-aside class="Aside" :style="'width:' + MenuWidth + 'px'">
          <div class="box">
            <div class="text" v-if="!isCollapse">Menu</div><br />
            <el-button type="success" @click="handoff" class="button">{{ MenuState }}</el-button>
          </div>

          <el-menu default-active="2" class="el-menu-vertical-demo" :collapse="isCollapse" active-text-color="#ffd04b"
            background-color="#545c64">

            <!-- <router-link to="/Article">
              <el-menu-item index="1">
                <el-icon class="is-loading">
                  <Loading />
                </el-icon>
                <template #title>文章管理</template>
</el-menu-item>
</router-link> -->

            <router-link to="/Peoples">

              <el-menu-item index="2">
                <!-- class="is-loading" -->
                <el-icon>
                  <Avatar />
                </el-icon>
                <template #title>人员管理</template>
              </el-menu-item>
            </router-link>

            <el-sub-menu index="3">
              <template #title>
                <el-icon>
                  <location />
                </el-icon>
                <span>订单管理</span>
              </template>
              <router-link to="/AwaitOrders"><el-menu-item index="3-1">待分配订单</el-menu-item></router-link>
              <router-link to="/AllOrders"><el-menu-item index="3-2">所有订单</el-menu-item></router-link>


            </el-sub-menu>

            <router-link to="/Vaccine">
              <el-menu-item index="4">
                <el-icon>
                  <setting />
                </el-icon>
                <template #title>疫苗管理</template>
              </el-menu-item>
            </router-link>

            <router-link to="/Chat">
              <el-menu-item index="5">
                <el-icon>
                  <ChatDotSquare />
                </el-icon>
                <template #title>用户对话</template>
              </el-menu-item>
            </router-link>

            <router-link to="/Aptitude">
              <el-menu-item index="6">
                <el-icon>
                  <setting />
                </el-icon>
                <template #title>资质信息管理</template>
              </el-menu-item>
            </router-link>
            <router-link to="/Test">
              <el-menu-item index="7">
                <el-icon>
                  <setting />
                </el-icon>
                <template #title>测试</template>
              </el-menu-item>
            </router-link>
            <!-- <router-link to="/Funcation">
              <el-menu-item index="5">
                <el-icon>
                  <setting />
                </el-icon>
                <template #title>功能管理</template>
              </el-menu-item>
            </router-link> -->

          </el-menu>
        </el-aside>
        <el-main class="Main border">
          <router-view v-slot="{ Component }" @ToFather="ToFather">
            <transition>
              <keep-alive exclude="Funcation,Aptitude,Article,Peoples,AllOrders,AwaitOrders,Vaccine,Test">
                <component :is="Component"></component>
              </keep-alive>
            </transition>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script setup>
import router from '@/router'
import { onBeforeMount, ref } from 'vue';
import { ElMessage } from 'element-plus'
const hospitalInfo = ref()
onBeforeMount(() => {
  if (window.sessionStorage.getItem("hospitalInfo")) {
    hospitalInfo.value = JSON.parse(window.sessionStorage.getItem("hospitalInfo"));
    console.log(hospitalInfo.value)
  }
})
const isCollapse = ref(false)
const Quit = () => {
  sessionStorage.removeItem("hospitalInfo");
  router.replace({ path: "/" });
}
const MenuWidth = ref()
const MenuState = ref("收回")
const handoff = () => {
  if (isCollapse.value) {
    isCollapse.value = !isCollapse.value
    MenuWidth.value = 300
    MenuState.value = "收回"
  } else {
    isCollapse.value = !isCollapse.value
    MenuWidth.value = 66
    MenuState.value = "展开"
  }
}
const ToFather = (info)=>{
  ElMessage({
            message: info+",请及时处理！",
            type: 'success',
        })
}
</script>


<style lang="scss" scoped>
a {
  text-decoration: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  height: 100%;
}

.is-active {
  background: #da5656;
}

.router-link-active {
  text-decoration: none;
}

.Header {
  height: 70px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;

  .logo {
    display: flex;
    align-items: center;
  }

  .top-right {
    display: flex;
    align-items: center;
    padding-right: 20px;

    img {
      margin-left: 8px;
      width: 30px;
    }
  }
}

.Aside {
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  box-sizing: border-box;
  overflow: hidden;
  /* 保证内容不会溢出 */
  transition: width 0.35s ease;

  /* 宽度过渡效果 */
  .box {
    display: flex;
    /* 使用 Flexbox 布局 */
    justify-content: space-between;
    /* 将子元素分布在容器两端 */
    align-items: center;
    /* 垂直居中 */
  }

  .text {
    font-weight: bold;
    font-size: larger;
    width: 50%;
    padding: 0 0 0 25px;
    box-sizing: border-box;
  }

  .button {
    flex: 1;
    margin-top: 5px;
    /* 按钮与菜单之间的上边距 */
  }
}

.Aside::-webkit-scrollbar {
  width: 2px;
}

.Aside::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.Main {
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  min-width: 400px;
  box-sizing: border-box;
  padding: 10px;
}

.border {
  border: sandybrown solid 1px;
}

.el-menu-vertical-demo {
  width: 100%;
  height: 100%;
}

.el-menu-vertical-demo-active {
  background: rgba(235, 157, 157, 0.8);
}
</style>
