<template>
  <div>
    功能管理
  </div>
</template>

<script setup>

</script>

<style>

</style>