import request from "@/utils/Request.js";

const baseUrl = '/Manager'
const FrontUrl = '/Index'

//获取验证码
export const getSMS = (phone) => {
  return request({
    url: FrontUrl + "/getSMS/" + phone,
    method: "get",
  });
}
//注册
export const postHospitalInfo = (info) => {
  return request({
    url: baseUrl + "/postHospitalInfo",
    method: "post",
    //   data:JSON.stringify(info),
    data: info
  });
}
//登录
export const verification = (info) => {
  return request({
    url: baseUrl + "/verification",
    method: "post",
    //   data:JSON.stringify(info),
    data: info
  });
}
//获取医院工作人员
export const getAllWorkers = (info) => {
  return request({
    url: baseUrl + "/getAllWorkers/"+info,
    method: "get",
  });
}
//获取医院所有订单
export const getAllOrders = (info) => {
  return request({
    url: baseUrl + "/getAllOrders/"+info,
    method: "get",
  });
}

//获取医院等待接单
export const getAwaitOrders = (info) => {
  return request({
    url: baseUrl + "/getAwaitOrders/"+info,
    method: "get",
    // params: info
  });
}

//获取所有疫苗
export const getAllVaccines = (info) => {
  return request({
    url: baseUrl + "/getAllVaccines",
    method: "get",
    params: info
  });
}

//获取医院信息
export const getHospitalInfo = (info) => {
  return request({
    url: baseUrl + "/getHospitalInfo/"+info,
    method: "get",
  });
}

//获取医院信息
export const UpdataHospital = (info) => {
  return request({
    url: baseUrl + "/UpdataHospital",
    method: "post",
    data: info
  });
}
// 删除订单
export const delOrder = (info) => {
  return request({
    url: FrontUrl + "/delOrder/"+info,
    method: "delete",
  });
}

// 新增人员
export const AddWorker = (info) => {
  return request({
    url: baseUrl + "/AddWorker",
    method: "post",
    data:info
  });
}
// 更新人员
export const UpdataWorker = (info) => {
  return request({
    url: baseUrl + "/UpdataWorker",
    method: "post",
    data:info
  });
}
// 删除人员
export const delWorker = (info) => {
  return request({
    url: baseUrl + "/delWorker/"+info,
    method: "delete",
  });
}

// 订单通过审核
export const PassOrder = (info) => {
  console.log(info)
  return request({
    url: baseUrl + "/PassOrder/"+info,
    method: "get",
  });
}
// 获取疫苗
export const getVaccinesByID = (info) => {
  return request({
    url: FrontUrl + "/getVaccinesByID/"+info,
    method: "get",
  });
}
// 新增疫苗
export const AddVaccine = (info) => {
  return request({
    url: baseUrl + "/AddVaccine",
    method: "post",
    data:info
  });
}
// 更新疫苗
export const UpdataVaccine = (info) => {
  return request({
    url: baseUrl + "/UpdataVaccine",
    method: "post",
    data:info
  });
}
// 删除疫苗
export const delVaccine = (info) => {
  return request({
    url: baseUrl + "/delVaccine/"+info,
    method: "delete",
  });
}






//退出
export function authLogout(params) {
  return request({
    url: baseUrl + "/logout",
    method: "get",
  });
}
//获取用户数据
export function getUserInfo(params) {
  return request({
    url: baseUrl + "/getUserInfo",
    method: "get",
    params: qs.stringfy(params)
  });
}

//其实，也不一定就是params，也可以是 query 还有 data 的呀！
//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。