import { createRouter, createWebHashHistory } from 'vue-router'
import RegistView from '../views/RegistView.vue'
import loginView from '../views/LoginView.vue'
import AdminView from '../views/AdminView.vue'
import Funcation from '../views/Subpages/Funcation.vue'
import Aptitude from '../views/Subpages/Aptitude.vue'
import Article from '../views/Subpages/Article.vue'
import Peoples from '../views/Subpages/Peoples.vue'
import AllOrders from '../views/Subpages/AllOrders.vue'
import AwaitOrders from '../views/Subpages/AwaitOrders.vue'
import Vaccine from '../views/Subpages/Vaccine.vue'
import Chat from '../views/Subpages/Chat.vue'
import Test from '../views/Subpages/Test.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: loginView,
    // meta:{ keepAlive: false}
  },
  {
    path: '/regist',
    name: 'regist',
    component: RegistView,
    // meta:{ keepAlive: false}
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    // meta:{ keepAlive: false},
    redirect:'/Peoples',
    children: [{
      path: '/Funcation',
      name: 'Funcation',
      component: Funcation,
      // meta:{ keepAlive: false}
    },
    {
      path: '/Aptitude',
      name: 'Aptitude',
      component: Aptitude,
      // meta:{ keepAlive: false}
    },
    {
      path: '/Article',
      name: 'Article',
      component: Article,
      // meta:{ keepAlive: false}
    },
    {
      path: '/Peoples',
      name: 'Peoples',
      component: Peoples,
      // meta:{ keepAlive: false}
    },
    {
      path: '/AwaitOrders',
      name: 'AwaitOrders',
      component: AwaitOrders,
      // meta:{ keepAlive: false}
    },
    {
      path: '/AllOrders',
      name: 'AllOrders',
      component: AllOrders,
      // meta:{ keepAlive: false}
    },
    {
      path: '/Vaccine',
      name: 'Vaccine',
      component: Vaccine,
      // meta:{ keepAlive: false}
    },
    {
      path: '/Chat',
      name: 'Chat',
      component: Chat,
      meta:{ keepAlive: true}
    },
    {
      path: '/Test',
      name: 'Test',
      component: Test,
      // meta:{ keepAlive: false}
    }
  ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  mode:'history',
  base:'/dist',
  routes
})

export default router
