<template>
    <div v-for="(item, index) in items" :key="index" 
         :class="{ 'selected': selectedItem === index }" 
         @click="selectedItem = index">
      <!-- 这里是你的内容 -->
      点击选中我
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      // 假设这是你的数据源
      const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];
  
      // 跟踪当前选中项的索引
      const selectedItem = ref(null);
  
      return {
        items,
        selectedItem
      };
    }
  };
  </script>
  
  <style>
  /* 添加选中样式 */
  .selected {
    background-color: yellow;
  }
  </style>
  