<template>
    <div>


    <div class="add">
        <el-button type="primary" plain @click="newInfo('NEW')">
            <el-icon>
                <Plus />
            </el-icon>
            新增疫苗
        </el-button>
    </div>
    <div>
        <el-table :data="PageVaccines" border style="width: 100%">
            <el-table-column prop="id" label="疫苗串号" />
            <el-table-column prop="vaccineName" label="疫苗名称" />
            <el-table-column prop="vaccinePrice" label="疫苗价格" />
            <el-table-column prop="vaccineType" label="疫苗类型" />
            <el-table-column prop="affiliatedHospital" label="所属医院" />
            <el-table-column prop="remainderNum" label="剩余库存" />
            <el-table-column prop="manufacturer" label="厂家" />
            <el-table-column prop="containerNum" label="装载" />
            <el-table-column prop="container" label="单位" />
            <el-table-column prop="measure" label="剂量" />
            <el-table-column prop="unit" label="剂量单位" />
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination background v-if="total" @current-change="handleCurrentChange" :page-size=2
                layout="total, prev, pager, next" :total=total />
        </div>
    </div>

    <div>
        <el-dialog v-model="DialogVisible" title="信息" width="700" align-center draggable>
            <!-- <template #content> -->
            <el-form :inline="true" :model="dialogForm" class="demo-form-inline">
                <el-form-item label="疫苗ID">
                    <el-input v-model="dialogForm.id" placeholder="疫苗ID" />
                </el-form-item>
                <el-form-item label="疫苗名称">
                    <el-input v-model="dialogForm.vaccineName" placeholder="疫苗名称" />
                </el-form-item>
                <el-form-item label="单价">
                    <el-input v-model="dialogForm.vaccinePrice" placeholder="单价" />
                </el-form-item>
                <el-form-item label="类型">
                    <el-input v-model="dialogForm.vaccineType" placeholder="类型" />
                </el-form-item>
                <el-form-item label="医院ID">
                    <el-input v-model="dialogForm.affiliatedHospital" :placeholder="FormHospitalid" disabled/>
                </el-form-item>
                <el-form-item label="库存">
                    <el-input v-model="dialogForm.remainderNum" placeholder="库存" />
                </el-form-item>
                <el-form-item label="厂家">
                    <el-input v-model="dialogForm.manufacturer" placeholder="厂家" />
                </el-form-item>
                <el-form-item label="单位内数量">
                    <el-input v-model="dialogForm.containerNum" placeholder="Such as '1支'" />
                </el-form-item>
                <el-form-item label="单位">
                    <el-input v-model="dialogForm.container" placeholder="Such as '盒'" />
                </el-form-item>
                <el-form-item label="剂量">
                    <el-input v-model="dialogForm.measure" placeholder="Such as '0.5'" />
                </el-form-item>
                <el-form-item label="计量单位">
                    <el-input v-model="dialogForm.unit" placeholder="Such as 'ml'" />
                </el-form-item>
            </el-form>
            <!-- </template> -->
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="Cancel">取消</el-button>
                    <el-button type="primary" @click="Submit">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>

    </div>
</div>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue';
import { getVaccinesByID, UpdataVaccine, delVaccine, AddVaccine } from '../../utils/API'
import { ElMessage } from 'element-plus'
const hospitalInfo = ref()
const Vaccines = ref()
const total = ref()
const FormHospitalid = ref()
const SunmitTowhere = ref('')
onBeforeMount(() => {
    if (window.sessionStorage.getItem("hospitalInfo")) {
        hospitalInfo.value = JSON.parse(window.sessionStorage.getItem("hospitalInfo"));
        FormHospitalid.value = hospitalInfo.value.id
        getVaccines();
    }

})
const dialogForm = ref({
    id: "",
    vaccineName: "",
    vaccinePrice: "",
    vaccineType: "",
    affiliatedHospital: "",
    remainderNum: "",
    Manufacturer: "",
    ContainerNum: "",
    Container:"",
    Measure:"",
    Unit:""
})

// 分页
const getVaccines = () => {
    getVaccinesByID(hospitalInfo.value.id).then((res) => {
        console.log(res)
        Vaccines.value = res.data
        total.value = Vaccines.value.length;
        getPageInfo();
    })
}
// 取消
const Cancel = () => {
    dialogForm.value = {}
    dialogForm.value.affiliatedHospital = FormHospitalid.value
    DialogVisible.value = false
}
// 确定
const Submit = () => {
    console.log("提交", dialogForm.value)
    if (SunmitTowhere.value == "NEW") {
        AddVaccine(dialogForm.value).then((res) => {
            if (res.status == 200) {
                ElMessage({
                    message: '新增成功',
                    type: 'success',
                })
                DialogVisible.value = false
                dialogForm.value = {}
                dialogForm.value.affiliatedHospital = FormHospitalid.value
                getVaccines();
                
            }
        })
    } else {
        UpdataVaccine(dialogForm.value).then((res) => {
            if (res.status == 200) {
                ElMessage({
                    message: '更新成功',
                    type: 'success',
                })
                DialogVisible.value = false
                dialogForm.value = {}
                getVaccines();
                
            }
        })
    }
}
// 新建
const newInfo = (info) => {
    DialogVisible.value = true
    SunmitTowhere.value = info
    dialogForm.value.affiliatedHospital = FormHospitalid.value
}
// 编辑
const handleEdit = (index, row) => {
    DialogVisible.value = true
    dialogForm.value = row
    SunmitTowhere.value = "UpData"
    // console.log(index, row)
}
// 删除
const handleDelete = (index, row) => {
    delVaccine(row.id).then((res) => {
            if (res.status == 200) {
                ElMessage({
                    message: '删除成功',
                    type: 'success',
                })
                getVaccines();
            }
        })
        console.log("删除", index, row)
    }
    

// 一页的数据
const PageVaccines = ref()
// 当前在第几页
const currentpage = ref(1)
const getPageInfo = () => {
    //清空pageTicket中的数据
    PageVaccines.value = [];
    // 获取当前页的数据
    for (let i = (currentpage.value - 1) * 2; i < total.value; i++) {
        //把遍历的数据添加到pageTicket里面
        PageVaccines.value.push(Vaccines.value[i]);
        //判断是否达到一页的要求
        if (PageVaccines.value.length === 2) break;
    }
}
// 分页按钮方法
//调整当前的页码
const handleCurrentChange = (pageNumber) => {
    console.log("当前页", pageNumber)
    //修改当前的页码
    currentpage.value = pageNumber;
    //数据重新分页
    getPageInfo()
}
const DialogVisible = ref(false)
</script>

<style lang="scss" scoped>
.pagination {
    position: absolute;
    bottom: 10px;
    right: 20px;
}

.add {
    float: right;
    margin: 0 0 10px 0;

    .el-button {
        width: 200px;
        font-size: 20px;
    }
}
</style>