<template>
    <div>
    <div class="add">
        <el-button type="primary" plain @click="newInfo('NEW')">
            <el-icon>
                <Plus />
            </el-icon>
            新增员工
        </el-button>
    </div>
    <div>
        <el-table :data="PageWorkers" border style="width: 100%">
            <el-table-column prop="id" label="工号" />
            <el-table-column prop="name" label="名称" />
            <el-table-column prop="hospitalid" label="所属医院ID" />
            <el-table-column prop="servicecontent" label="服务资质" />
            <el-table-column prop="ossurl" label="头像" width="100">
                <template #default="scope">
                    <el-image :src="scope.row.ossurl" style="width: 100%;height: 60px"
                        :preview-src-list="[scope.row.ossurl]" :preview-teleported="true"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="sex" label="性别" />
            <el-table-column prop="star" label="星级" />
            <el-table-column prop="tel" label="电话" />
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination background  v-if="total" @current-change="handleCurrentChange" :page-size=2
                layout="prev, pager, next" :total=total />
            <!-- <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total=total :page-size="2" /> -->
        </div>
    </div>

    <div>
        <el-dialog v-model="DialogVisible" title="信息" width="700" align-center draggable>
            <!-- <template #content> -->
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="员工姓名">
                    <el-input v-model="formInline.name" placeholder="姓名" />
                </el-form-item>

                <el-form-item label="性别">
                    <el-select v-model="formInline.sex" placeholder="" style="width: 100px;">
                        <el-option label="男" value="男" />
                        <el-option label="女" value="女" />
                    </el-select>
                </el-form-item>
                <el-form-item label="所属医院">
                    <el-input :v-model="formInline.hospitalid" :placeholder="hospitalInfo.hospitalName" clearable
                        disabled />
                </el-form-item>

                <el-form-item label="星级">
                    <el-input v-model="formInline.star" placeholder="星级" clearable />
                </el-form-item>
                <el-form-item label="服务资质">
                    <el-input v-model="formInline.servicecontent" placeholder="资质" clearable />
                </el-form-item>
                <el-form-item label="电话 ">
                    <el-input v-model="formInline.tel" placeholder="电话" clearable />
                </el-form-item>
                <el-form-item label="头像链接 ">
                    <el-input v-model="formInline.ossurl" placeholder="请输入网络链接" clearable />
                </el-form-item>
            </el-form>
            <!-- </template> -->
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="Cancel">取消</el-button>
                    <el-button type="primary" @click="Submit">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>

    </div>
</div>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue';
import { getAllWorkers, UpdataWorker, delWorker, AddWorker } from '../../utils/API'
import { ElMessage } from 'element-plus'
const hospitalInfo = ref()
const Workers = ref()
const total = ref()
const FormHospitalid = ref()
const SunmitTowhere = ref('')
onBeforeMount(() => {
    if (window.sessionStorage.getItem("hospitalInfo")) {
        hospitalInfo.value = JSON.parse(window.sessionStorage.getItem("hospitalInfo"));
        FormHospitalid.value = hospitalInfo.value.id
        getWorkers();
    }

})
const formInline = ref({
    id: "",
    name: "",
    hospitalid: "",
    sex: "",
    tel: "",
    star: "",
    servicecontent: "",
    ossurl: ""
})

// 分页
const getWorkers = () => {
    getAllWorkers(hospitalInfo.value.id).then((res) => {
        // console.log(res)
        Workers.value = res.data
        total.value = parseInt(Workers.value.length);
        getPageInfo();
    })
}
// 取消
const Cancel = () => {
    formInline.value = {}
    formInline.value.hospitalid = FormHospitalid.value
    DialogVisible.value = false
}
// 确定
const Submit = () => {
    console.log("提交", formInline.value)
    if (SunmitTowhere.value == "NEW") {
        AddWorker(formInline.value).then((res) => {
            if (res.status == 200) {
                ElMessage({
                    message: '新增成功',
                    type: 'success',
                })
                DialogVisible.value = false
                formInline.value = {}
                formInline.value.hospitalid = FormHospitalid.value
                getWorkers();
                
            }
        })
    } else {
        UpdataWorker(formInline.value).then((res) => {
            if (res.status == 200) {
                ElMessage({
                    message: '更新成功',
                    type: 'success',
                })
                DialogVisible.value = false
                formInline.value = {}
                getWorkers();
                
            }
        })
    }
}
// 新建
const newInfo = (info) => {
    DialogVisible.value = true
    SunmitTowhere.value = info
    formInline.value.hospitalid = FormHospitalid.value
}
// 编辑
const handleEdit = (index, row) => {
    DialogVisible.value = true
    formInline.value = row
    SunmitTowhere.value = "UpData"
    // console.log(index, row)
}
// 删除
const handleDelete = (index, row) => {
    delWorker(row.id).then((res) => {
            if (res.status == 200) {
                ElMessage({
                    message: '删除成功',
                    type: 'success',
                })
                getWorkers();
            }
        })
        console.log("删除", index, row)
    }
    

// 一页的数据
const PageWorkers = ref()
// 当前在第几页
const currentpage = ref(1)
const getPageInfo = () => {
    //清空pageTicket中的数据
    PageWorkers.value = [];
    // 获取当前页的数据
    for (let i = (currentpage.value - 1) * 2; i < total.value; i++) {
        //把遍历的数据添加到pageTicket里面
        PageWorkers.value.push(Workers.value[i]);
        //判断是否达到一页的要求
        if (PageWorkers.value.length === 2) break;
    }
}
// 分页按钮方法
//调整当前的页码
const handleCurrentChange = (pageNumber) => {
    console.log("当前页", pageNumber)
    //修改当前的页码
    currentpage.value = pageNumber;
    //数据重新分页
    getPageInfo()
}
const DialogVisible = ref(false)
</script>

<style lang="scss" scoped>
.pagination {
    position: absolute;
    bottom: 10px;
    right: 20px;
}

.add {
    float: right;
    margin: 0 0 10px 0;

    .el-button {
        width: 200px;
        font-size: 20px;
    }
}
</style>