<template>
    <div class="content">
        <div class="form">
            <el-form :model="formInline" :inline="true" label-width="auto" style="max-width: 100vh" v-if="formInline"
                label-position="top" size="large">
                <el-form-item label="医院串号"><el-input v-model="formInline.id" disabled /> </el-form-item>
                <el-form-item label="医院名称"><el-input v-model="formInline.hospitalName" /> </el-form-item>
                <el-form-item label="医院等级"><el-input v-model="formInline.hospitalStar" /> </el-form-item>
                <el-form-item label="医院东经"><el-input v-model="formInline.hospitalLongitude" /> </el-form-item>
                <el-form-item label="医院北纬"><el-input v-model="formInline.hospitalLatitude" /> </el-form-item>
                <el-form-item label="省&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp份"><el-input
                        v-model="formInline.hospitalProvince" /> </el-form-item>
                <el-form-item label="市&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp"><el-input v-model="formInline.hospitalCity" />
                </el-form-item>
                <el-form-item label="区&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp"><el-input v-model="formInline.hospitalDistrict" />
                </el-form-item>
                <el-form-item label="下属疫苗"><el-input v-model="formInline.underlingId" disabled /> </el-form-item>
                <el-form-item label="医院Logo"><el-input v-model="formInline.hospitalImg" /> </el-form-item>
                <el-form-item label="详细地址"><el-input v-model="formInline.hospitalAddress" type="textarea" />
                </el-form-item>
                <el-form-item label="电&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp话"><el-input v-model="formInline.hospitalTel" />
                </el-form-item>
                <el-form-item label="医院介绍"><el-input :rows="4" v-model="formInline.hospitalIntroduce" type="textarea"
                        style="display: block;" />
                </el-form-item>
                <el-form-item label="特色服务"><el-input v-model="formInline.hospitalDistinctive" /> </el-form-item>
                <el-form-item label="支持服务"><el-input v-model="formInline.hospitalServices" /> </el-form-item>
                <el-form-item label="注册电话"><el-input v-model="formInline.createphone" disabled /> </el-form-item>
            </el-form>
            <el-button type="primary" round plain style="width: 50%;" @click="updataInfo">
                更新
            </el-button>
        </div>
    </div>

</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { getHospitalInfo,UpdataHospital } from '../../utils/API'
import { ElMessage } from 'element-plus'
const hospitalInfo = ref()
const formInline = ref()
onBeforeMount(() => {
    if (window.sessionStorage.getItem("hospitalInfo")) {
        hospitalInfo.value = JSON.parse(window.sessionStorage.getItem("hospitalInfo"));
        getHospitalInfo(hospitalInfo.value.id).then((res) => {
            formInline.value = res.data
            console.log(res.data)
        });
    }



})
const updataInfo = () => {
    UpdataHospital(formInline.value).then((res) => {
        // console.log(res)
        if (res.status == 200) {
            
            ElMessage({
                message: '更新成功',
                type: 'success',
            })
        }
    })
}
</script>

<style lang="scss" scoped>
.content {

    width: 100%;
}

.form {
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
}

.button {

    width: 100%;
}
</style>